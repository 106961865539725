export default [
    {
        id: "ce_1v1JOwAAAYwnOLSF",
        created: "Desde el 26 ene. 2021 hasta el 26 ene. 2024",
        event_type: "CRE",
        acc_id: "acc_md850oAYz2vRsAGyC5",
        name: "ESTEBAN ANDRES SAEZ DURAN • RUN 18394516-5",
        created_by: 'María Levin Rebolledo',
        transmitter: 'E-CERTCHILE CA FIRMA ELECTRONICA SIMPLE',
        icon: 'home',
        organizations: [
            {
                id: "acc_yNFXfO1pYWBjDllw6E",
                base_currency: {
                    id: "PEN",
                    conversion: null,
                    name: "Sol peruano",
                    name_en: "Sol peruano",
                    default: true,
                    is_chargeable: true,
                    available: true,
                    rounded: false,
                    country: "PE"
                },
                me: null,
                address: "Av. Defensores del Morro Nro. 1277 (.)",
                name: "BOTICAS IP S.A.C.",
                brand: 'brand--inkafarma.png',
                tax_id: "20608430301",
                color_asset: "#0583AE",
                enable_customers: false,
                early_access_features: false,
                email: "blopez@farmaciasperuanas.pe",
                email_template: "classic",
                logo: null,
                phone: "",
                tax_settings: {},
                website: "",
                template_mode: "standard",
                auto_msg: true,
                status: "active",
                created: "2023-10-16T17:32:20.704984-03:00",
                updated: "2023-10-16T17:32:53.659222-03:00",
                country: "PE",
                state: "PE150108"
            },
            {
                id: "acc_vfLQZSSAj4GHbApBAK",
                base_currency: {
                    id: "PEN",
                    conversion: null,
                    name: "Sol peruano",
                    name_en: "Sol peruano",
                    default: true,
                    is_chargeable: true,
                    available: true,
                    rounded: false,
                    country: "PE"
                },
                me: "mem_3VPjItnQtsWlQbU1WY",
                address: "Av. Elias Aguirre Nro. 1107 (con 9 de Diciembre) Loreto",
                name: "Jorsa de la Selva S.A.C",
                brand: 'brand--dojorsa.png',
                tax_id: "20531502508",
                color_asset: "#0583AE",
                enable_customers: false,
                early_access_features: false,
                email: "blopez@farmaciasperuanas.pe",
                email_template: "classic",
                logo: null,
                phone: "",
                tax_settings: {},
                website: "",
                template_mode: "standard",
                auto_msg: true,
                status: "active",
                created: "2023-10-16T17:22:49.666326-03:00",
                updated: "2023-10-16T17:22:49.666347-03:00",
                country: "PE",
                state: "PE160112"
            },
            {
                id: "acc_IfyWdJl9xKP6KyPHDy",
                base_currency: {
                    id: "PEN",
                    conversion: null,
                    name: "Sol peruano",
                    name_en: "Sol peruano",
                    default: true,
                    is_chargeable: true,
                    available: true,
                    rounded: false,
                    country: "PE"
                },
                me: null,
                address: "Cal. Victor Alzamora Nro. 147",
                brand: 'brand--mifarma.png',
                name: "Mifarma S.A.C",
                tax_id: "20512002090",
                color_asset: "#0583AE",
                enable_customers: false,
                early_access_features: false,
                email: "blopez@farmaciasperuanas.pe",
                email_template: "classic",
                logo: null,
                phone: "",
                tax_settings: {},
                website: "",
                template_mode: "standard",
                auto_msg: true,
                status: "active",
                created: "2023-10-16T17:10:13.121035-03:00",
                updated: "2023-11-30T11:33:52.807576-03:00",
                country: "PE",
                state: "PE140106"
            },
            {
                id: "acc_xQD7KHsdc4GJZddPuj",
                base_currency: {
                    id: "PEN",
                    conversion: null,
                    name: "Sol peruano",
                    name_en: "Sol peruano",
                    default: true,
                    is_chargeable: true,
                    available: true,
                    rounded: false,
                    country: "PE"
                },
                me: null,
                address: "Cal. Victor Alzamora Nro. 147",
                name: "Farmacias Peruanas S.A.C.",
                brand: 'brand--farmacias-peruanas.png',
                tax_id: "20605900012",
                color_asset: "#0583AE",
                enable_customers: false,
                early_access_features: false,
                email: "blopez@farmaciasperuanas.pe",
                email_template: "classic",
                logo: null,
                phone: "",
                tax_settings: {},
                website: "",
                template_mode: "standard",
                auto_msg: true,
                status: "active",
                created: "2023-10-16T17:51:01.587873-03:00",
                updated: "2023-10-16T17:51:16.285423-03:00",
                country: "PE",
                state: "PE140106"
            },
            {
                id: "acc_2rgvQmtvXF1Sy9h2Wu",
                base_currency: {
                    id: "PEN",
                    conversion: null,
                    name: "Sol peruano",
                    name_en: "Sol peruano",
                    default: true,
                    is_chargeable: true,
                    available: true,
                    rounded: false,
                    country: "PE"
                },
                me: null,
                address: "Av. Defensores del Morro Nro. 1277 (Ex Fabrica Luchetti)",
                name: "Inretail Pharma S.A.",
                tax_id: "20331066703",
                color_asset: "#0583AE",
                enable_customers: false,
                early_access_features: false,
                email: "fgonzales@farmaciasperuanas.pe",
                email_template: "classic",
                logo: null,
                phone: "",
                tax_settings: {},
                website: "",
                template_mode: "standard",
                auto_msg: true,
                status: "active",
                created: "2023-10-16T17:43:17.815182-03:00",
                updated: "2023-11-27T18:31:11.563837-03:00",
                country: "PE",
                state: "PE150108"
            },
            {
                id: "acc_L7LUd6uSGglzJSW168",
                base_currency: {
                    id: "PEN",
                    conversion: null,
                    name: "Sol peruano",
                    name_en: "Sol peruano",
                    default: true,
                    is_chargeable: true,
                    available: true,
                    rounded: false,
                    country: "PE"
                },
                me: null,
                address: "San Borja",
                name: "COMPAÑÍA FOOD RETAIL S.A.C",
                brand: 'brand--vivanda.png',
                tax_id: "20608300393",
                color_asset: "#0583AE",
                enable_customers: false,
                early_access_features: false,
                email: "elvis.velasquez@spsa.pe",
                email_template: "classic",
                logo: null,
                phone: null,
                tax_settings: {},
                website: null,
                template_mode: "standard",
                auto_msg: true,
                status: "active",
                created: "2023-09-26T17:38:37.481902-03:00",
                updated: "2023-10-18T14:41:27.478117-03:00",
                country: "PE",
                state: "PE150130"
            },
            {
                id: "acc_ULle0YyL621UKMkb1P",
                base_currency: {
                    id: "PEN",
                    conversion: null,
                    name: "Sol peruano",
                    name_en: "Sol peruano",
                    default: true,
                    is_chargeable: true,
                    available: true,
                    rounded: false,
                    country: "PE"
                },
                me: "mem_y5ebTCZrbfxUYpwikK",
                address: "San Borja",
                name: "COMPAÑÍA HARD DISCOUNT S.A.C.",
                brand: 'brand--mass.png',
                tax_id: "20608280333",
                color_asset: "#0583AE",
                enable_customers: false,
                early_access_features: false,
                email: "elvis.velasquez@spsa.pe",
                email_template: "classic",
                logo: null,
                phone: null,
                tax_settings: {},
                website: null,
                template_mode: "standard",
                auto_msg: true,
                status: "active",
                created: "2023-09-26T17:38:08.922196-03:00",
                updated: "2023-09-26T17:38:08.922220-03:00",
                country: "PE",
                state: "PE150130"
            },
            {
                id: "acc_dvA5wshESXdPRJAVD1",
                base_currency: {
                    id: "PEN",
                    conversion: null,
                    name: "Sol peruano",
                    name_en: "Sol peruano",
                    default: true,
                    is_chargeable: true,
                    available: true,
                    rounded: false,
                    country: "PE"
                },
                me: null,
                address: "San Borja",
                name: "MAKRO SUPERMAYORISTA S.A.",
                tax_id: "20492092313",
                color_asset: "#0583AE",
                brand: 'brand--makro.png',
                enable_customers: false,
                early_access_features: false,
                email: "elvis.velasquez@spsa.pe",
                email_template: "classic",
                logo: null,
                phone: null,
                tax_settings: {},
                website: null,
                template_mode: "standard",
                auto_msg: true,
                status: "active",
                created: "2023-09-26T17:36:27.422513-03:00",
                updated: "2023-09-26T17:36:27.422553-03:00",
                country: "PE",
                state: "PE150130"
            }
        ]
    },
    {
        id: "ce_1v1JOwAAAYwnOLSF",
        created: "Desde el 26 ene. 2021 hasta el 26 ene. 2024",
        event_type: "SNT",
        created_by: 'María Levin Rebolledo',
        acc_id: "acc_md850oAYz2vRsAGyC5",
        transmitter: 'E-CERTCHILE CA FIRMA ELECTRONICA SIMPLE',
        name: "ESTEBAN ANDRES SAEZ DURAN • RUN 18394516-5",
        icon: 'cart',
        organizations: [
            {
                id: "acc_yNFXfO1pYWBjDllw6E",
                base_currency: {
                    id: "PEN",
                    conversion: null,
                    name: "Sol peruano",
                    name_en: "Sol peruano",
                    default: true,
                    is_chargeable: true,
                    available: true,
                    rounded: false,
                    country: "PE"
                },
                me: null,
                address: "Av. Defensores del Morro Nro. 1277 (.)",
                name: "BOTICAS IP S.A.C.",
                brand: 'brand--inkafarma.png',
                tax_id: "20608430301",
                color_asset: "#0583AE",
                enable_customers: false,
                early_access_features: false,
                email: "blopez@farmaciasperuanas.pe",
                email_template: "classic",
                logo: null,
                phone: "",
                tax_settings: {},
                website: "",
                template_mode: "standard",
                auto_msg: true,
                status: "active",
                created: "2023-10-16T17:32:20.704984-03:00",
                updated: "2023-10-16T17:32:53.659222-03:00",
                country: "PE",
                state: "PE150108"
            },
            {
                id: "acc_vfLQZSSAj4GHbApBAK",
                base_currency: {
                    id: "PEN",
                    conversion: null,
                    name: "Sol peruano",
                    name_en: "Sol peruano",
                    default: true,
                    is_chargeable: true,
                    available: true,
                    rounded: false,
                    country: "PE"
                },
                me: "mem_3VPjItnQtsWlQbU1WY",
                address: "Av. Elias Aguirre Nro. 1107 (con 9 de Diciembre) Loreto",
                name: "Jorsa de la Selva S.A.C",
                brand: 'brand--dojorsa.png',
                tax_id: "20531502508",
                color_asset: "#0583AE",
                enable_customers: false,
                early_access_features: false,
                email: "blopez@farmaciasperuanas.pe",
                email_template: "classic",
                logo: null,
                phone: "",
                tax_settings: {},
                website: "",
                template_mode: "standard",
                auto_msg: true,
                status: "active",
                created: "2023-10-16T17:22:49.666326-03:00",
                updated: "2023-10-16T17:22:49.666347-03:00",
                country: "PE",
                state: "PE160112"
            },
            {
                id: "acc_IfyWdJl9xKP6KyPHDy",
                base_currency: {
                    id: "PEN",
                    conversion: null,
                    name: "Sol peruano",
                    name_en: "Sol peruano",
                    default: true,
                    is_chargeable: true,
                    available: true,
                    rounded: false,
                    country: "PE"
                },
                me: null,
                address: "Cal. Victor Alzamora Nro. 147",
                brand: 'brand--mifarma.png',
                name: "Mifarma S.A.C",
                tax_id: "20512002090",
                color_asset: "#0583AE",
                enable_customers: false,
                early_access_features: false,
                email: "blopez@farmaciasperuanas.pe",
                email_template: "classic",
                logo: null,
                phone: "",
                tax_settings: {},
                website: "",
                template_mode: "standard",
                auto_msg: true,
                status: "active",
                created: "2023-10-16T17:10:13.121035-03:00",
                updated: "2023-11-30T11:33:52.807576-03:00",
                country: "PE",
                state: "PE140106"
            },
            {
                id: "acc_xQD7KHsdc4GJZddPuj",
                base_currency: {
                    id: "PEN",
                    conversion: null,
                    name: "Sol peruano",
                    name_en: "Sol peruano",
                    default: true,
                    is_chargeable: true,
                    available: true,
                    rounded: false,
                    country: "PE"
                },
                me: null,
                address: "Cal. Victor Alzamora Nro. 147",
                name: "Farmacias Peruanas S.A.C.",
                brand: 'brand--farmacias-peruanas.png',
                tax_id: "20605900012",
                color_asset: "#0583AE",
                enable_customers: false,
                early_access_features: false,
                email: "blopez@farmaciasperuanas.pe",
                email_template: "classic",
                logo: null,
                phone: "",
                tax_settings: {},
                website: "",
                template_mode: "standard",
                auto_msg: true,
                status: "active",
                created: "2023-10-16T17:51:01.587873-03:00",
                updated: "2023-10-16T17:51:16.285423-03:00",
                country: "PE",
                state: "PE140106"
            },
            {
                id: "acc_2rgvQmtvXF1Sy9h2Wu",
                base_currency: {
                    id: "PEN",
                    conversion: null,
                    name: "Sol peruano",
                    name_en: "Sol peruano",
                    default: true,
                    is_chargeable: true,
                    available: true,
                    rounded: false,
                    country: "PE"
                },
                me: null,
                address: "Av. Defensores del Morro Nro. 1277 (Ex Fabrica Luchetti)",
                name: "Inretail Pharma S.A.",
                tax_id: "20331066703",
                color_asset: "#0583AE",
                enable_customers: false,
                early_access_features: false,
                email: "fgonzales@farmaciasperuanas.pe",
                email_template: "classic",
                logo: null,
                phone: "",
                tax_settings: {},
                website: "",
                template_mode: "standard",
                auto_msg: true,
                status: "active",
                created: "2023-10-16T17:43:17.815182-03:00",
                updated: "2023-11-27T18:31:11.563837-03:00",
                country: "PE",
                state: "PE150108"
            },
            {
                id: "acc_ULle0YyL621UKMkb1P",
                base_currency: {
                    id: "PEN",
                    conversion: null,
                    name: "Sol peruano",
                    name_en: "Sol peruano",
                    default: true,
                    is_chargeable: true,
                    available: true,
                    rounded: false,
                    country: "PE"
                },
                me: "mem_y5ebTCZrbfxUYpwikK",
                address: "San Borja",
                name: "COMPAÑÍA HARD DISCOUNT S.A.C.",
                brand: 'brand--mass.png',
                tax_id: "20608280333",
                color_asset: "#0583AE",
                enable_customers: false,
                early_access_features: false,
                email: "elvis.velasquez@spsa.pe",
                email_template: "classic",
                logo: null,
                phone: null,
                tax_settings: {},
                website: null,
                template_mode: "standard",
                auto_msg: true,
                status: "active",
                created: "2023-09-26T17:38:08.922196-03:00",
                updated: "2023-09-26T17:38:08.922220-03:00",
                country: "PE",
                state: "PE150130"
            }
        ]
    }
]
