<template>
  <v-row class="pb-12">
    <!-- header -->
    <mini-header :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :addPadding="true">
      <template v-slot:main>
        <v-btn class="ml-2" @click="modalCertificate=true" color="primary">
          <v-icon left>mdi-upload </v-icon>Subir certificado
        </v-btn>
      </template>
    </mini-header>
    <!-- end header -->
    <v-row>
      <v-col v-for="(org, index) in certificatesList" :key="`${org.id}-${index}`" cols="6">
        <v-hover v-slot="{ hover }">
          <!-- :to="{name: 'FoliosRetrieve', params: {id: org.id}}" -->
          <v-card class="pa-5 rounded-md" :elevation="hover ? 5 : 0" @click="current=org, dialog=true" flat>
            <v-row no-gutters align="start">
              <v-col cols="10">
                <v-list class="transparent v-list-form-extended-medium">
                  <v-list-item class="py-0">
                    <v-list-item-title class="d-flex align-center body-1 secondary--text font-weight-medium mr-n6">
                      <v-icon class="mr-2" color="secondary" size="14">mdi-account</v-icon> Nombre
                    </v-list-item-title>
                    <v-list-item-subtitle class="ma-0 body-1 ml-2">
                      {{ org.name }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="py-0">
                    <v-list-item-title class="d-flex align-center body-1 secondary--text font-weight-medium mr-n6">
                      <v-icon class="mr-2" color="secondary" size="14">mdi-calendar-blank</v-icon> Validez
                    </v-list-item-title>
                    <v-list-item-subtitle class="ma-0 body-1 ml-2">
                      {{ org.created }}
                    </v-list-item-subtitle>
                  </v-list-item>
                  <v-list-item class="py-0">
                    <v-list-item-title class="d-flex align-center body-1 secondary--text font-weight-medium mr-n6">
                      <v-icon class="mr-2" color="secondary" size="14">mdi-stamper</v-icon> Emisor
                    </v-list-item-title>
                    <v-list-item-subtitle class="ma-0 body-1 ml-2 d-flex align-center">
                      <img class="mr-2" :src="require(`@/assets/product-holding/ecert.png`)" alt="ecert" :height="16">
                      <span class="text-truncate d-inline-block" style="max-width: 90%">{{ org.transmitter}}</span>
                    </v-list-item-subtitle>
                  </v-list-item>
                </v-list>
              </v-col>
              <v-spacer />
              <v-menu close-on-click close-on-content-click offset-y :nudge-bottom="8" left transition="slide-y-transition">
                <template v-slot:activator="{on, attrs}">
                  <v-btn class="mr-n4 mt-3" v-bind="attrs" v-on="on" small text :ripple="false"><v-icon size="24" color="fontBody">mdi-dots-vertical</v-icon></v-btn>
                </template>
                <v-list class="pa-0">
                  <v-list-item class="px-2" :to="{ name: 'OrganizationList' }">
                    <v-list-item-title class="body-1 text--secondary">Ver todas las organizaciones</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-row>
            <v-sheet class="transparent mt-3 mb-4" height="25">
              <span class="secondary--text font-weight-medium body-1 d-inline align-center"><v-icon class="mr-2" color="secondary" size="14">mdi-domain</v-icon> Organizaciones</span>
            </v-sheet>
            <div class="d-flex">
              <div class="d-flex mx-2" style="position: relative" v-if="org.organizations.length">
              <v-tooltip color="bgSearch" right transition="scale-transition" max-width="300">
                <template v-slot:activator="{on, attrs}">
                <div v-for="item, i in org.organizations" :key="item.id" v-bind="attrs" v-on="on">
                  <v-avatar color="bgSearch" class="avatar-orgunit ml-n3" v-if="item.brand && (i >=0 || i <= org.organizations.length - 1)" small rounded="pill" size="36" absolute>
                    <img class="ma-0 pa-0" :src="require(`@/assets/brands/${item.brand?.toLowerCase()}`)" :alt="item.brand" height="50px">
                  </v-avatar>
                </div>
               </template>
                 <div class="py-2">
                  <span class="d-block px-5 py-1" v-for="(item) in org.organizations" :key="item.id">
                    <v-avatar color="bgSearch" class="avatar-orgunit" v-if="item.brand" rounded="pill" size="30">
                      <v-img  rounded="pill" :src="require(`@/assets/brands/${item.brand?.toLowerCase()}`)" :alt="item.brand" cover width="30px" height="30px" />
                    </v-avatar>
                    <v-icon class="pr-1 mr-2" v-else :color="item.color" x-small>mdi-domain</v-icon>
                    <span class="ml-2">{{ item.name }}</span>
                  </span>
                </div>
                </v-tooltip>
              </div>
              <v-avatar color="background" class="avatar-orgunit ml-n5"  small rounded="pill" size="38" absolute>
                <v-icon color="secondary">mdi-plus</v-icon>
              </v-avatar>
            </div>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <!-- dialog -->
    <v-dialog v-model="dialog" width="958" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="d-flex align-center px-5 subtitle-2 fontBody--secondary font-weight-semibold"> <v-icon class="mr-2" color="secondary" size="20">mdi-account-group</v-icon>Detalle de la unidad de negocio</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialog=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="pb-4 px-0">
          <v-list class="transparent px-5 v-list-form-extended-medium">
            <v-list-item>
              <v-list-item-title class="d-flex align-center body-1 secondary--text font-weight-medium">
                <v-icon class="mr-2" color="secondary" size="14">mdi-account-group</v-icon> Nombre
              </v-list-item-title>
              <v-list-item-subtitle class="ma-0 body-1 ml-2">
                {{ current.name }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-0">
              <v-list-item-title class="d-flex align-center body-1 secondary--text font-weight-medium">
                <v-icon class="mr-2" color="secondary" size="14">mdi-account</v-icon> Creado por
              </v-list-item-title>
              <v-list-item-subtitle class="ma-0 body-1 ml-2">
                {{ current.created_by }}
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="py-0">
              <v-list-item-title class="d-flex align-center body-1 secondary--text font-weight-medium">
                <v-icon class="mr-2" color="secondary" size="14">mdi-calendar-blank</v-icon> Creado el
              </v-list-item-title>
              <v-list-item-subtitle class="ma-0 body-1 ml-2">
                {{ current.created | date }}
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
          <v-divider class="my-2" />
          <v-row class="px-5 mt-5" no-gutters align="center">
            <v-col cols="9">
              <span class="secondary--text font-weight-semibold body-1 d-inline align-center">Organizaciones</span>
            </v-col>
            <v-spacer />
            <v-col>
              <v-btn class="ml-2" @click="dialog=false, modaAddedAccount=true" outlined>
                <v-icon left>mdi-domain-plus </v-icon>Agregar organización
              </v-btn>
            </v-col>
          </v-row>
          <div class="mt-3 px-5">
            <v-row class="mt-0 px-0" align="center">
              <v-col cols="4" class="pa-2" v-for="(d, i) in accountsList" :key="i">
                <v-hover v-slot="{ hover }">
                  <v-sheet class="pb-2 pt-2 px-1 text-center" outlined rounded="md" :elevation="hover ? 6 : 0" :ripple="false">
                    <v-row align="center" no-gutters>
                      <v-col cols="10" class="d-flex pb-0">
                        <v-card class="ml-2 mr-3 transparent pt-1" width="20" flat style="border-radius: 2px !important;">
                          <v-badge avatar bordered overlap>
                            <template v-slot:badge>
                              <v-avatar :size="20">
                                <v-img :src="require(`@/assets/flags/flag-${d.country?.toLowerCase()}.svg`)" height="20px"></v-img>
                              </v-avatar>
                            </template>
                            <v-avatar v-if="d.brand" class="py-0 outlined" rounded="sm" size="26">
                              <img :src="require(`@/assets/brands/${d.brand?.toLowerCase()}`)" :alt="d.brand">
                            </v-avatar>
                            <v-avatar v-else class="py-0" color="background" rounded="sm" size="26">
                              <v-icon size="16" color="fontBody">mdi-domain</v-icon>
                            </v-avatar>
                          </v-badge>
                        </v-card>
                        <div class="text-left ml-2 fontBody--text font-weight-bold subtitle-2 d-inline-block text-truncate font-weight-semibold" style="max-width: 80%;"> {{d.name}}
                          <span class="d-block body-2 fontDraw--text">RUC {{d.tax_id}}</span>
                        </div>
                      </v-col>
                      <v-spacer />
                      <v-col cols="1" class="pr-10 pb-0">
                        <v-menu close-on-click close-on-content-click offset-y :nudge-bottom="8" left transition="slide-y-transition">
                          <template v-slot:activator="{on, attrs}">
                            <v-btn v-bind="attrs" v-on="on" small :ripple="false" text><v-icon size="24" color="fontBody">mdi-dots-vertical</v-icon></v-btn>
                          </template>
                          <v-list class="px-4">
                            <v-list-item :to="{ name: 'OrganizationRetrieveUpdate', params: { id: d.id } }">
                              <v-list-item-title class="body-1 text--secondary"><v-icon class="mt-n1 text--secondary" left size="18">mdi-eye</v-icon>Ver organización</v-list-item-title>
                            </v-list-item>
                            <v-list-item class="pt-1" @click="dialog=false, current=d, deleteOrganization=true">
                              <v-list-item-title class="body-1 text--secondary"><v-icon class="mt-n1 text--secondary" left size="18">mdi-delete</v-icon>Eliminar organización de la <br> <span class="pl-6">unidad de negocio</span></v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-col>
                    </v-row>
                  </v-sheet>
                </v-hover>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- end dialog -->
    <!-- dialog confirm delete -->
    <v-dialog v-model="deleteOrganization" width="792" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Eliminar organización del certificado</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="deleteOrganization=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pt-2 pb-4" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-null.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-4 pl-4">
              <div class="mt-1 mb-3">
                <v-icon class="mr-2" color="secondary" size="18">mdi-domain</v-icon>
                <v-avatar v-if="current.brand" class="py-0 outlined mr-2" rounded="sm" size="18">
                  <img :src="require(`@/assets/brands/${current.brand?.toLowerCase()}`)" :alt="current.brand">
                </v-avatar>
                <span class="body-2 font-weight-medium text-uppercase">{{ current.name }} - RUC {{ current.tax_id }}</span>
              </div>
              <span class="d-block body-1 pt-2">Al <span class="font-weight-bold"> la organización del certificado,</span> esta se organización no se eliminará, sino que quedará sin certificado, por lo que tendrás que <span class="font-weight-bold"> cargarle o asignarle un nuevo certificado</span> para poder continuar operando con esta organización.</span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="deleteOrganization=false" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="deleteOrganization=false" :disabled="confirm.toLowerCase() !== 'confirmar'">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->

    <!-- dialog confirm delete organit -->
    <v-dialog v-model="deleteOrganiut" width="792" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Eliminar unidad de negocio </v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="deleteOrganiut=false,  confirmar = ''" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pt-2 pb-4" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-null.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-4 pl-4">
              <div class="mt-1 mb-3">
                <span class="d-flex align-center body-2 font-weight-medium text-uppercase">{{ current.name }}
                  <v-icon class="mr-2" color="secondary" size="18">mdi-home</v-icon>
                </span>
              </div>
              <span class="d-block body-1 pt-2">Al eliminar la unidad de negocio, <span class="font-weight-bold"> no se están eliminando las organizaciones asociadas.</span> Estas quedarán sin una unidad de negocio asignada..</span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="deleteOrganiut=false, confirmar = ''" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="deleteOrganiut=false, confirmar = ''" :disabled="confirm.toLowerCase() !== 'confirmar'">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->

    <!-- dialog create -->
    <v-dialog v-model="dialogCreate" width="850" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card class="pa-0">
        <v-toolbar flat height="46" class="transparent">
          <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">{{isEdit ? 'Editar' : 'Crear'}} unidad de negocio</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-row align="center" justify="center" class="mr-3">
              <v-btn @click="dialogCreate=false, isEdit = false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
            </v-row>
          </v-toolbar-items>
        </v-toolbar>
        <v-divider />
        <v-card-text class="py-2">
          <v-row class="pt-2 pb-4" align="center" no-gutters>
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-create-organiut.svg`)" :width="178" />
            </v-col>
            <v-col class="py-0">
              <v-row no-gutters>
                <v-col class="pa-0">
                  <v-list class="v-list-form-extended transparent pa-0">
                    <v-list-item class="pb-2">
                      <v-list-item-title>Nombre</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-text-field v-model="name" outlined hide-details required single-line dense maxlength="32" />
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pt-2">
                      <v-list-item-title>Icono</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-col cols="2">
                          <v-select v-model="icon" :items="iconList" outlined hide-details required single-line dense maxlength="36">
                            <template v-slot:item="data">
                              <v-list-item-title><v-icon color="secondary" size="16">mdi-{{data.item}}</v-icon></v-list-item-title>
                            </template>
                            <template v-slot:selection="{ item }">
                              <v-icon class="pt-1" color="secondary" size="16">mdi-{{item}}</v-icon>
                            </template>
                          </v-select>
                        </v-col>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item class="pt-2">
                      <v-list-item-title>Organizaciones</v-list-item-title>
                      <v-list-item-subtitle>
                        <v-col class="pt-2" cols="12">
                          <v-autocomplete
                            v-model="organizations"
                            hide-details
                            single-line
                            color="primary"
                            dense
                            outlined
                            :items="accountsList"
                            multiple
                            :chips="false"
                            item-text="name"
                            item-value="id"
                            :label="organizations.length ? '' : 'Selecciona organizaciones'"
                            :prefix="organizations.length >= 1 ? `${organizations.length === 1 ? `${organizations.length} seleccionado` : `${organizations.length} seleccionados`}` : ''"
                            no-data-text="No existen organizaciones para mostrar"
                          >
                            <template v-slot:selection="{ props }">
                              <span v-if="props" style=""></span>
                            </template>
                          </v-autocomplete>
                        </v-col>
                      </v-list-item-subtitle>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title></v-list-item-title>
                      <v-list-item-subtitle>
                        <v-row no-gutters>
                          <v-col class="pa-0 mt-2 d-flex">
                            <div>
                              <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(m, i) in chipOrg(organizations).slice(0,3)" :key="`${m}${i}`" close @click:close="memberOrg(m, i)" color="lightBlue" small label close-icon="mdi-close">
                                <v-img class="mr-2" v-if="m.brand" :src="require(`@/assets/brands/${m.brand?.toLowerCase()}`)" :alt="m.brand" :max-width="15" :max-height="15" />
                                {{m.name }}
                              </v-chip>
                              <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-if="organizations.length > 3" color="lightBlue" small label>
                                {{ organizations.length }} más
                              </v-chip>
                            </div>
                          </v-col>
                        </v-row>
                      </v-list-item-subtitle>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="px-5 py-2">
          <v-spacer />
          <v-btn @click="dialogCreate=false, isEdit = false" outlined>Cancelar</v-btn>
          <v-btn @click="dialogCreate=false,  isEdit = false" :loading="$store.state.irs.createLoader" color="primary">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog grant -->

     <!-- dialog certificate -->
     <v-dialog v-model="modalCertificate" width="860" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card flat>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Nuevo certificado digital</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="modalCertificate=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 py-4">
          <v-row class="pt-2 pb-4" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-astro.svg`)" :width="188" />
            </v-col>
            <v-col class="pr-4 pl-4">
              <v-list class="v-list-form-2 transparent">
                <v-list-item class="pa-0">
                  <v-list-item-title>
                    Organizaciónes
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-autocomplete
                      v-model="organizations"
                      hide-details
                      single-line
                      color="primary"
                      dense
                      outlined
                      :items="accountsList"
                      multiple
                      :chips="false"
                      item-text="name"
                      item-value="id"
                      :menu-props="{ overflowY: true, transition: 'slide-y-transition', 'max-width': '400px' }"
                      :label="organizations.length ? '' : 'Selecciona organizaciones'"
                      :prefix="organizations.length >= 1 ? `${organizations.length === 1 ? `${organizations.length} seleccionado` : `${organizations.length} seleccionados`}` : ''"
                      no-data-text="No existen organizaciones para mostrar"
                    >
                      <template v-slot:selection="{ props }">
                        <span v-if="props" style=""></span>
                      </template>
                    </v-autocomplete>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item v-if="organizations.length">
                  <v-list-item-title></v-list-item-title>
                  <v-list-item-subtitle>
                    <v-row no-gutters>
                      <v-col class="pa-0 mt-2 d-flex">
                        <div>
                          <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(m, i) in chipOrg(organizations).slice(0,2)" :key="`${m}${i}`" close @click:close="memberOrg(m, i)" color="lightBlue" small label close-icon="mdi-close">
                            <v-img class="mr-2" v-if="m.brand" :src="require(`@/assets/brands/${m.brand?.toLowerCase()}`)" :alt="m.brand" :max-width="15" :max-height="15" />
                            {{m.name }}
                          </v-chip>
                          <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-if="organizations.length > 2" color="lightBlue" small label>
                            {{ organizations.length }} más
                          </v-chip>
                        </div>
                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="pt-3">
                  <v-list-item-title>
                    Archivo del certificado
                    <v-tooltip color="bgSearch" right transition="scale-transition" max-width="200px">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="secondary">mdi-information</v-icon>
                      </template>
                      <span class="d-block px-3 py-2">Asegúrate que el certificado digital tenga formato/extensión .p12</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-file-input type="file" accept="application/x-pkcs12" prepend="mdi-paper-clip" background-color="transparent" hide-details placeholder="No se eligió ningún archivo" solo flat chips />
                  </v-list-item-subtitle>
                </v-list-item>
                <v-list-item class="mt-2">
                  <v-list-item-title>
                    Contraseña
                    <v-tooltip color="bgSearch" right transition="scale-transition" max-width="200px">
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on" small color="secondary">mdi-information</v-icon>
                      </template>
                      <span class="d-block px-3 py-2">La contraseña que ingresaste al comprar el certificado digital</span>
                    </v-tooltip>
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <v-col>
                      <v-text-field type="password" outlined required single-line dense hide-details />
                    </v-col>
                  </v-list-item-subtitle>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer />
          <v-btn @click="modalCertificate=false" outlined color="secondary">Cancelar</v-btn>
          <v-btn @click="modalCertificate=false" color="primary">Subir certificado</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog certificate -->

     <!-- dialog adde accounts -->
    <v-dialog v-model="modaAddedAccount" width="760" persistent no-click-animation scrollable overlay-color="overlay">
      <v-card flat>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold"><v-icon class="mr-2" color="secondary" size="14">mdi-domain</v-icon> Organizaciones</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="modalAddeAccount=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="px-5 pt-4 pb-2">
          <span>Selecciona una o más organizaciones que deseas agregar al certificado</span>
          <v-list class="v-list-form-2 transparent mt-2">
            <v-list-item class="pa-0">
              <v-list-item-subtitle>
                <v-autocomplete
                  v-model="organizations"
                  hide-details
                  single-line
                  color="primary"
                  dense
                  outlined
                  :items="accountsList"
                  multiple
                  :chips="false"
                  item-text="name"
                  item-value="id"
                  :menu-props="{ overflowY: true, transition: 'slide-y-transition', 'max-width': '400px' }"
                  :label="organizations.length ? '' : 'Selecciona organizaciones'"
                  :prefix="organizations.length >= 1 ? `${organizations.length === 1 ? `${organizations.length} seleccionado` : `${organizations.length} seleccionados`}` : ''"
                  no-data-text="No existen organizaciones para mostrar"
                >
                  <template v-slot:selection="{ props }">
                    <span v-if="props" style=""></span>
                  </template>
                </v-autocomplete>
              </v-list-item-subtitle>
            </v-list-item>
            <v-list-item class="mt-2" v-if="organizations.length">
              <v-list-item-subtitle>
                <v-row no-gutters>
                  <v-col class="pa-0 mt-2 d-flex">
                    <div>
                      <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-for="(m, i) in chipOrg(organizations).slice(0,4)" :key="`${m}${i}`" color="lightBlue" small label close-icon="mdi-close">
                        <v-img class="mr-2" v-if="m.brand" :src="require(`@/assets/brands/${m.brand?.toLowerCase()}`)" :alt="m.brand" :max-width="15" :max-height="15" />
                        {{m.name }}
                      </v-chip>
                      <v-chip class="mr-2 mb-2 font-weight-light text-lowercase overline chip-users" v-if="organizations.length > 4" color="lightBlue" small label>
                        {{ organizations.length - 4 }} más
                      </v-chip>
                    </div>
                  </v-col>
                </v-row>
              </v-list-item-subtitle>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer />
          <v-btn @click="modalAddeAccount=false" outlined color="secondary">Cancelar</v-btn>
          <v-btn @click="modaAddedAccount=false, confirmAddedAccount=true" color="primary">Agregar organización</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog adde accounts -->

    <!-- dialog confirm delete organit -->
    <v-dialog v-model="confirmAddedAccount" width="792" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Agregar organización del certificado</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="confirmAddedAccount=false, confirmar = ''" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pb-4" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-null.svg`)" :width="148" />
            </v-col>
            <v-col class="pr-4 pl-4">
              <span class="d-block body-1 pt-2">Al agregar <span class="font-weight-bold"> la nueva organización al certificado</span> esta quedará asociada al mismo. Esto significa que podrás <span class="font-weight-bold"> operar sin problemas</span> con esta nueva organización.</span>
              <span class="d-block body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="confirmAddedAccount=false, confirmar=''" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="confirmAddedAccount=false, confirmar = ''" :disabled="confirm.toLowerCase() !== 'confirmar'">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->

  </v-row>
</template>
<script>
import MiniHeader from '@/components/commons/MiniHeader'
import certificatesList from '@/data/certificatesList'
import accountsList from '@/data/accountsList' // example
import GenericViewMixin from '@/mixins/GenericViewMixin'

export default {
  components: {
    MiniHeader
  },
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    confirmAddedAccount: false,
    dialogCreate: false,
    modaAddedAccount: false,
    modalCertificate: false,
    organizations: [],
    name: '',
    icon: '',
    isEdit: true,
    deleteOrganization: false,
    deleteOrganiut: false,
    current: {},
    confirm: '',
    dialog: false,
    certificatesList: certificatesList,
    accountsList: accountsList,
    breadcrumbs: {
     main: 'Certificados'
   },
   iconList: ['cart', 'car', 'table-chair', 'silverware-fork-knife', 'bag-suitcase', 'baguette', 'paw', 'fish', 'glass-wine', 'radioactive', 'store', 'truck', 'food-fork-drink', 'hospital-box', 'gas-station', 'pine-tree', 'baby-carriage', 'basketball', 'cow', 'flower-tulip', 'hamburger', 'stadium', 'printer-pos', 'traffic-light', 'wrench', 'home', 'mortar-pestle-plus', 'lamp', 'account', 'ambulance', 'barley', 'format-paint', 'heart', 'slot-machine', 'tooth', 'vacuum', 'hanger', 'bank', 'pencil-ruler', 'airplane', 'alarm-light', 'broom', 'ferry', 'glass-mug-variant', 'human-male-female-child', 'liquor', 'server', 'text-box-outline', 'tshirt-crew']
  }),
  computed: {
    chipOrg () {
      return (val) => {
        return this.accountsList?.filter((item) => val?.some((elm) => elm === item.id)) ?? []
      }
    }
  },
  methods: {
    memberOrg (item, index) {
      this.organizations.splice(index, 1)
    },
    handlerEdit () {
      this.dialogCreate = true
      this.isEdit = true
      this.name = this.current.name
      this.icon = this.current.icon
      this.organizations = this.current.organizations
    }
  }
}
</script>
<style>
.v-list-form-extended-medium .v-list-item__title {
  flex: 130px
}
</style>